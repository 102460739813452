import { useImperativeHandle, forwardRef, useContext, useState } from 'react';
import { useEffect } from "react";
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import { useForm } from "react-hook-form";
import { FormInputText } from "components/form/FormInputText";
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';
import { addPark, updatePark, deletePark } from "services/Parks.Service";
import AuthContext from 'context/Auth/auth';
import ConfirmationDialog from "helpers/ConfirmationDialog";
import { IPark } from 'models/IPark';


const FormGrid = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'column',
}));



interface IFormInput {
    Id: number;
    ParkId: number;
    Name: string;
    Address: string;
    City: string;
    State: string;
    Zip: string;
    Latitude: number;
    Longitude: number;
    Zoom: number;
}

interface ParkFormProps {
    currentPark: IPark;
    currentOrg: any;
    latitude: number | null;
    longitude: number | null;
    ref: any;
    onDelete: (selectedPark: IPark) => void;
    onSave: (selectedPark: IPark, updatedDataPark: IPark) => void;
    handleSaving: (value: boolean) => void;
}


const defaultValues: IFormInput = {
    Id: 0,
    ParkId: 0,
    Name: "",
    Address: "",
    City: "",
    State: "",
    Zip: "",
    Latitude: 0,
    Longitude: 0,
    Zoom: 15,

};

export type ParkFormRef = {
    submitForm: () => void;
    showConfirm: () => void;

};


const ParkForm = forwardRef<ParkFormRef, ParkFormProps>(({ currentPark, currentOrg, onDelete, onSave, handleSaving, latitude, longitude }, ref) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const authContext = useContext(AuthContext);
    const schema = Yup.object().shape({
        Name: Yup.string().required("Name is required").max(255, "Name cannot exceed 255 characters"),
        Address: Yup.string().required("Address is required").max(255, "Address cannot exceed 255 characters"),
        City: Yup.string().required("City is required").max(255, "City cannot exceed 255 characters"),
        State: Yup.string().required("State is required").max(255, "State cannot exceed 255 characters"),
        Zip: Yup.string().required("Zip is required").max(255, "Zip cannot exceed 255 characters"),
        Latitude: Yup.number()
            .min(-90, "Latitude must be between -90 and 90")
            .max(90, "Latitude must be between -90 and 90")
            .required("Latitude is required"),
        Longitude: Yup.number()
            .min(-180, "Longitude must be between -180 and 180")
            .max(180, "Longitude must be between -180 and 180")
            .required("Longitude is required"),
        Zoom: Yup.number()
            .min(10, "Zoom level must be between 10 and 20")
            .max(20, "Zoom level must be between 10 and 20")
            .required("Zoom is required")
    });

    const { handleSubmit, control, setValue, reset, formState: { errors } } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
        shouldFocusError: true,
        criteriaMode: "all",
        reValidateMode: "onChange",
    });
    const onSubmit = (data: any) => callSubmit(data);
    const callSubmit = async (data: IFormInput) => {
        try {
            handleSaving(true);

            let response;
            if (currentPark.Id === 0) {
                // Add new park
                response = await addPark(authContext, currentOrg.Id, data);
                data.Id = response.data.Id;
                data.ParkId = response.data.Id;
            } else {
                // Update existing park
                response = await updatePark(authContext, currentOrg.Id, data);
            }

            onSave(currentPark, data); // Execute onSave with updated data
        } catch (error) {
            console.error(error);
        } finally {
            handleSaving(false); // Always stop saving indicator
        }
    };


    const showConfirmDialog = (): void => {
        setShowConfirmation(true);
    };

    const handleDelete = (): void => {
        deletePark(authContext, currentPark).then((_data) => {
            onDelete(currentPark);
            setShowConfirmation(false);
        });
    };

    const handleParkSelected = (): void => {
        defaultValues.ParkId = currentPark.ParkId;
        defaultValues.Name = currentPark.Name;
        defaultValues.Address = currentPark.Address;
        defaultValues.City = currentPark.City;
        defaultValues.State = currentPark.State;
        defaultValues.Zip = currentPark.Zip;
        defaultValues.Latitude = currentPark.Latitude;
        defaultValues.Longitude = currentPark.Longitude;
        defaultValues.Zoom = currentPark.Zoom;
        reset(defaultValues)
    };

    const handleConfirmDialogClose = (): void => {
        setShowConfirmation(false);

    };

    useImperativeHandle(ref, () => ({
        submitForm: () => handleSubmit(onSubmit)(),
        showConfirm: () => showConfirmDialog(),
    }));

    useEffect(() => {
        handleParkSelected();
    }, [currentPark]);

    useEffect(() => {
        if(latitude && longitude){
            setValue('Latitude', latitude);
            setValue('Longitude', longitude);
        }
    }, [latitude, longitude]);

    return (
        <>
            <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
                <Box
                    component="form"
                    sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 1 }}>
                    <Grid container spacing={4}>
                        <FormGrid size={{ xs: 12 }}>
                            <FormControl>
                                <FormInputText name="Name" control={control} errors={errors} label="Park name" />
                            </FormControl>
                        </FormGrid>
                        <FormGrid size={{ xs: 12 }}>
                            <FormControl>
                                <FormInputText name="Address" control={control} errors={errors} label="Address" />
                            </FormControl>
                        </FormGrid>
                        <FormGrid size={{ xs: 5 }}>
                            <FormControl>
                                <FormInputText name="City" control={control} errors={errors} label="City" />
                            </FormControl>
                        </FormGrid>
                        <FormGrid size={{ xs: 4 }}>
                            <FormControl>
                                <FormInputText name="State" control={control} errors={errors} label="State" />
                            </FormControl>
                        </FormGrid>
                        <FormGrid size={{ xs: 3 }}>
                            <FormControl>
                                <FormInputText name="Zip" control={control} errors={errors} label="Zip Code" />
                            </FormControl>
                        </FormGrid>
                        <FormGrid size={{ xs: 5 }}>
                            <FormControl>
                                <FormInputText name="Latitude" control={control} errors={errors} label="Latitude" type='number' />
                            </FormControl>
                        </FormGrid>
                        <FormGrid size={{ xs: 5 }}>
                            <FormControl>
                                <FormInputText name="Longitude" control={control} errors={errors} label="Longitude" type='number' />
                            </FormControl>
                        </FormGrid>
                        <FormGrid size={{ xs: 2 }}>
                            <FormControl>
                                <FormInputText name="Zoom" control={control} errors={errors} label="Zoom" type='number' />
                            </FormControl>
                        </FormGrid>
                    </Grid>
                </Box>
            </Stack>
            <ConfirmationDialog
                open={showConfirmation}
                close={handleConfirmDialogClose}
                confirm={handleDelete}
                warning={false}
            />
        </>
    );
});

export default ParkForm;